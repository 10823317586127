var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-left justify-center q-mb-md line"},[_c('q-card',{class:{
			'q-mb-md q-pa-sm q-mr-sm container-size-large': true,
			'cursor-pointer': _vm.is_overview && _vm.logged_user.customer_permission != 'SENDER',
		},on:{"click":function($event){return _vm.goToDetails('details')}}},[_c('div',[_c('strong',[_vm._v("Message ID: ")]),_vm._v(_vm._s(_vm.report_details.id))]),_c('div',[_c('strong',[_vm._v("Message name: ")]),_vm._v(_vm._s(_vm.report_details.name))]),_c('div',[_c('strong',[_vm._v("Message type: ")]),_vm._v(_vm._s(_vm.session_type))]),_c('div',[_c('strong',[_vm._v("Started by: ")]),_vm._v(_vm._s(_vm.report_details.started_by))]),_c('div',[_c('strong',[_vm._v("Start time: ")]),(_vm.report_details.date_start)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.date_start,'timezone', _vm.preferred_timezone, 'DD/MM/YYYY HH:mm'))+" ")]):_vm._e(),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","offset":[10, 10]}},[_vm._v(" "+_vm._s(_vm.preferred_timezone_preview)+" ")])],1)]),_c('q-card',{class:{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': _vm.is_overview && _vm.logged_user.customer_permission != 'SENDER',
		},on:{"click":function($event){return _vm.goToDetails('recipients')}}},[_c('div',[_c('strong',[_vm._v(" Recipients:")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.user_stats_data.total_recipients))])])])]),_c('q-card',{class:{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': _vm.is_overview && _vm.logged_user.customer_permission != 'SENDER',
		},on:{"click":function($event){return _vm.goToDetails('excluded')}}},[_c('div',[_c('strong',[_vm._v(" Excluded:")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.user_stats_data.excluded_users))])])])]),_c('q-card',{class:{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': _vm.is_overview && _vm.logged_user.customer_permission != 'SENDER',
		},on:{"click":function($event){return _vm.goToDetails('delivered')}}},[_c('div',[_c('strong',[_vm._v(" Reached:")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.user_stats_data.delivered_recipients))])]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.percentage_delivered)+"%")])])])]),(_vm.report_details.settings.polling_options || _vm.session_type === 'Conference')?_c('q-card',{class:{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': _vm.is_overview && _vm.logged_user.customer_permission != 'SENDER',
		},on:{"click":function($event){return _vm.goToDetails('responded')}}},[(_vm.session_type === 'Conference')?_c('span',[_c('strong',[_vm._v("Accepted ")]),_vm._v(" "),_c('br')]):_c('span',[_c('strong',[_vm._v("Responded: ")]),_vm._v(" "),_c('br')]),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.user_stats_data.total_responded_recipients))])]),_c('br'),_c('span',{staticClass:"text-h4"},[_c('b',[_vm._v(_vm._s(_vm.percentage_responded)+"%")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }