<template>
	<div class="row text-left justify-center q-mb-md line">
	<q-card
		@click="goToDetails('details')"
		:class="{
			'q-mb-md q-pa-sm q-mr-sm container-size-large': true,
			'cursor-pointer': is_overview && logged_user.customer_permission != 'SENDER',
		}"
	>
		<div><strong>Message ID: </strong>{{report_details.id}}</div>
		<div><strong>Message name: </strong>{{report_details.name}}</div>
		<div><strong>Message type: </strong>{{ session_type }}</div>
		<div><strong>Started by: </strong>{{report_details.started_by}}</div>
		<div><strong>Start time: </strong>
			<span v-if='report_details.date_start'>{{date_start | moment('timezone', preferred_timezone, 'DD/MM/YYYY HH:mm')}} </span>
			<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
				{{preferred_timezone_preview}}
			</q-tooltip>
		</div>
	</q-card>
	<q-card
		@click="goToDetails('recipients')"
		:class="{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': is_overview && logged_user.customer_permission != 'SENDER',
		}"
	>
		<div>
			<strong> Recipients:</strong> <br>
			<span class="text-h4"><b>{{user_stats_data.total_recipients}}</b></span>
		</div>
	</q-card>
	<q-card
		@click="goToDetails('excluded')"
		:class="{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': is_overview && logged_user.customer_permission != 'SENDER',
		}"
	>
		<div>
			<strong> Excluded:</strong> <br>
			<span class="text-h4"><b>{{user_stats_data.excluded_users}}</b></span>
		</div>
	</q-card>
	<q-card
		@click="goToDetails('delivered')"
		:class="{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': is_overview && logged_user.customer_permission != 'SENDER',
		}"
	>
		<div>
			<strong> Reached:</strong> <br>
			<span class="text-h4"><b>{{user_stats_data.delivered_recipients}}</b></span> <br>
			<span class="text-h4"><b>{{percentage_delivered}}%</b></span>
		</div>
	</q-card>
	<q-card
		v-if="report_details.settings.polling_options || session_type === 'Conference'"
		@click="goToDetails('responded')"
		:class="{
			'q-mb-md q-pa-sm q-mx-sm container-size-small': true,
			'cursor-pointer': is_overview && logged_user.customer_permission != 'SENDER',
		}"
	>
		<span v-if="session_type === 'Conference'"><strong>Accepted </strong> <br></span>
		<span v-else><strong>Responded: </strong> <br></span>
		<span class="text-h4"><b>{{user_stats_data.total_responded_recipients}}</b></span><br>
		<span class="text-h4"><b>{{percentage_responded}}%</b></span>
	</q-card>
</div>
</template>

<script>
import MessageFormsAPI from '@/services/api/MessageForm.js';
import { mapActions, mapGetters } from "vuex";
import moment from 'moment-timezone'

export default {
	name: "SessionDetails",
	props: {
		"chartDatas": Array,
		"preferred_timezone": String,
		"is_overview": Boolean,
	},
	data() {
		return {
			date_start: null,
			check_session_init_interval: null,
			preferred_timezone_preview: "",
			percentage_delivered: 0,
			percentage_responded: 0,
		}
	},
	computed: {
		...mapGetters([
			'logged_user',
			'file',
			'query_param',
			'resend_task_id',
			'report_details',
			'user_stats_data',
		]),
		session_type () {
			return this.report_details.session_type.charAt(0).toUpperCase() + this.report_details.session_type.slice(1);
		}
	},
	methods: {
		...mapActions(["set_active_item", 'updateFileTimeout', 'checkUsersStatistic']),
		async checkSessionInitResult() {
			this.check_session_init_interval = setInterval(async () => {
				try {
					let response = await MessageFormsAPI.getRunResult(this.resend_task_id);
					this.$store.commit('setErrorMessage', "");
					this.$store.commit('setTimeoutMessage', "");
					clearInterval(this.check_session_init_interval);
					setTimeout(()=>{this.check_session_init_interval = null;}, 500);
					this.check_session_init_interval = null;
					this.$store.commit('setResendMessage', null);

					this.$store.commit(
						"setTimeoutMessage",
						"Your message has been successfully initialised. Your report will open in a new tab."
					);
					this.$q.notify("Your message has been successfully initialised. Your report will open in a new tab.")
					this.$store.commit('setTimeoutMessage', "");
					setTimeout( () => {
						let routeData = this.$router.resolve({
							name: 'Dispatch Overview',
							params: {id: response.session_id}
						})
						window.open(routeData.href, '_blank');
						this.set_active_item(205);
					}, 2000);
				} catch(error) {
					clearInterval(this.check_session_init_interval);
					setTimeout(()=>{this.check_session_init_interval = null;}, 500);

					this.$store.commit('setResendMessage', null);

					if (typeof(error.data) == 'string') {
						this.$store.commit('setErrorMessage', error.data);
					} else {
						let error_message = "";
						for (let key in error.data) {
							error_message += error.data[key];
						}
						this.$store.commit('setErrorMessage',  error_message);
					}

				}
			}, 1000);
		},
		goToDetails(type){
			if (this.logged_user.customer_permission != 'SENDER') {
				if(!this.is_overview){
					return
				}
				if(type === 'details' || type === 'recipients') {
					this.$router.push({ name: 'Dispatch Details', params: { id: this.report_details.id }});
				}else if (type === 'excluded'){
					this.$router.push({ name: 'Dispatch Details', query: { section: 'reply ', filter: 'Excluded Users' }});
				}else if(type === 'delivered'){
					this.$router.push({ name: 'Dispatch Details', query: { section: 'state ', filter: 'Successful Delivery' }});
				}else if(type === 'responded'){
					this.$router.push({ name: 'Dispatch Details', query: { section: 'reply', filter: 'All' }});
				}
			}
		}
	},
	async updated() {
		if (this.resend_task_id && !this.check_session_init_interval) {
			this.$q.loading.show({
				delay: 400,
			})
			await this.checkSessionInitResult();
			this.$q.loading.hide()
		}
	},
	beforeDestroy() {
		if(this.interval){
			clearInterval(this.interval);
		}
	},
	destroyed(){
		this.$store.commit('setTimeoutMessage', "");
	},
	async created() {
		this.date_start = moment.tz(this.report_details.date_start, 'UTC');
		// close the success message
		this.$store.commit('updateFile', {...this.file, generating:false});
		this.preferred_timezone_preview = `Timezone: ${this.preferred_timezone}`;
	},
	watch: {
		chartDatas:{
			deep: true,
			handler(){
				this.checkUsersStatistic(this.$route.params.id);
			}
		},
		user_stats_data: function() {
			if (this.user_stats_data.delivered_recipients && this.user_stats_data.total_recipients) {
				let percent = ((this.user_stats_data.delivered_recipients / this.user_stats_data.total_recipients) * 100)
				this.percentage_delivered = Math.round(percent * 10) / 10
				if (this.report_details.settings.polling_options || this.session_type === 'Conference'){
					let percent_respond = ((this.user_stats_data.total_responded_recipients / this.user_stats_data.delivered_recipients) * 100)
					if (percent_respond){
						this.percentage_responded = Math.round(percent_respond * 10) / 10
					}else{
						this.percentage_responded = 0
					}
				}
			}
		}
	}
}
</script>
<style lang="scss">
	.line{
		line-height: 1.6;
		@media(max-width: 599px){
			justify-content: space-between;
		}
	}
	.container-size-large{
		min-width: 32%;
		min-height: 130px;
		@media(max-width: 599px){
			min-width: 100%;
		}
	}
	.container-size-small{
		min-width: 130px;
		min-height: 130px;
		@media(max-width: 599px){
			margin: 16px 0px;
			min-width: 49%;
			width: 49%;
		}
	}
</style>
